.card {
    transition: all 0.33s ease;
    border: 2px solid rgba(@dark-3, 0.7);
    border-radius: 20px;
    background-color: @white;
    text-align: left;
    overflow: hidden;
    img {
        border-radius: 8px;
    }
    &.--padding {
        padding: 12px;
        &.--lg {
            padding: 24px;
        }
    }
    &.--small {
        border-radius: 8px;
        margin-bottom: 12px;
    }
    &.--large {
        .card__header {
            height: 64px;
            line-height: 64px;
        }
    }

    &__header {
        height: 50px;
        line-height: 50px;
        padding: 0px 12px;
        font-size: 1.125rem;
        border-bottom: 1px solid @dark-3;
        font-weight: 700;
        display: block;
        width: 100%;
        .ant-btn-link, .link {
            float: right;
            padding: 0;
            height: 50px;
        }

        .anticon {
            position: relative;
            top: 3px;
        }
    }

    &__body {
        padding: 14px 16px;
    }
    &.--hoverable {
        cursor: pointer;
        &:hover {
            border-color: @primary;
        }
        &.--active {
            border-color: @success-color;
        }
    }
    &.--no-border {
        border: 0;
        &:hover {
            border: 0;
        }

        .card__header {
            border: 0;
        }
    }

    &.--mb {
        margin-bottom: 12px;
    }
    &.active {
        border: 2px solid @primary;
    }
}

.card_assignment {
    padding: 16px;
    border: 0;
    position: relative;
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 70%;
    margin-bottom: 1rem;
    button {
        margin-top: 12px;
        width: max-content;
    }

    &.--stretch {
        height: 100%;
    }

    &__title {
        width: 66%;
    }

    .__status {
        position: absolute;
        top: 16px;
        right: 16px;
        padding: 4px 12px;
        border-radius: 999px;
        color: @white;
    }

    .ant-typography {
        .assignment_timedown {
            color: @error-color;
            display: inline-block;
            margin-left: 12px;
            font-weight: 800;
        }
    }
    &.__TEST {
        background-color: @danger-color-light;
        background-image: url(/assets/images/card-test.png);

        .__status {
            background-color: @error-color;
        }
    }
    &.__NORMAL {
        background-color: @info-color-light;
        background-image: url(/assets/images/card-normal.png);

        .__status {
            background-color: @info-color;
        }
    }
    &.__NOGRADE {
        background-color: @success-color-light;
        background-image: url(/assets/images/card-read.png);

        .__status {
            background-color: @success-color;
        }
    }

    .ant-progress {
        width: 150px;
        .ant-progress-text {
            margin-left: -48%;
            position: relative;
            z-index: 1;
            font-weight: 700;
        }
        .breakpoint-lg({
                width: 200px;
            });
    }
}
