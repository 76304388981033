.cb_exercise_mode {
    height: 100dvh;
    .ant-layout {
        background-color: @light-1;
    }
    .ant-layout-sider-children {
        width: 100%;
        height: calc(100dvh - 165px);
    }
    &__header {
        height: 70px;
        background-color: @white;
        border-bottom: 1px solid @dark-2;
        padding: 12px;
        .ant-typography {
            margin-bottom: 0;
        }
        .view_history {
            display: none;
        }
        .breakpoint-sm({
            height: 96px;
            padding: 24px;
            .view_history {
                display: block;
            }
        });
    }

    .exercise_subheader {
        .custom_progress {
            position: relative;
            width: 500px;
            margin-right: 2rem;
            height: 24px;
            border-radius: 12px;
            background-color: @primary-light-1;
            display: none;
            .breakpoint-sm({
                display: block;
            });
            img {
                height: 60px;
                width: auto;
                min-width: 45px;
                position: absolute;
                display: block;
            }
            .bee,
            .house {
                top: -22px;
                right: -20px;
            }
            .line {
                // transition: width 0.2s ease;
                position: absolute;
                height: 100%;
                // background-color: @success-color;
                border-radius: 12px;
                z-index: 1;
            }
        }
        .timeout {
            font-size: 1.5rem;
            font-weight: 800;
        }
        .timeout_text {
            display: block;
            white-space: nowrap;
            .breakpoint-sm({
                display: none;
            });
        }
    }

    &__score_ticket {
        width: 100%;
        margin: 0 auto 1rem;
        flex-direction: column;
        .breakpoint-tablet({
            flex-direction: row;
        });

        &__score {
            width: 100%;
            .breakpoint-tablet({
                width: 25%;
            });
            text-align: center;
            .card__header {
                background-color: @primary;
            }
            .score {
                font-size: 3.5rem;
                color: @error-color;
                font-weight: 700;
                margin: 0;
                height: calc(100% - 50px);
            }
        }
        &__info {
            width: 100%;
            .breakpoint-tablet({
                width: 75%;
            });
            .card__header {
                background-color: @success-color-light;
            }
            > div {
                padding: 12px;
            }
        }
    }

    &__controls {
        background-color: @white;
        border-right: 2px solid @dark-2;
        padding: 12px;
        display: none;
        .breakpoint-sm({
            display: flex;
        });
        .breakpoint-lg({
            padding: 24px;
        });
    }

    &__body {
        padding: 12px;
        overflow: auto;
        background-color: @light-1;
        width: 100%;
        user-select: none;
        .breakpoint-sm({
            max-width: 1280px;
            margin: 0 auto;
            width: 100%;
            padding: 1rem;
        });
    }

    &__section {
        width: 100%;
        position: relative;
        margin-bottom: 1rem;
        .ant-image {
            width: 50%;
            max-width: 50%;
            height: calc(100% - 64px);
            overflow: auto;
            position: absolute;
            right: 0;
            top: 64px;

            .ant-image-mask {
                opacity: 0;
            }

            img {
                border-radius: 12px;
                width: 100%;
                z-index: 0;
                user-select: none;
            }
            &::-webkit-scrollbar {
                display: none; /* Hide scrollbar for Chrome, Safari and Opera */
            }
        }
        .card__header {
            background-color: @info-color-light;
            display: flex;
        }
    }

    &__content {
        position: relative;
        img {
            border-radius: 12px;
            z-index: 0;
            user-select: none;
        }
        .matching_reset {
            position: absolute;
            right: 1rem;
            top: 1rem;
            opacity: 0.6;
            span:not(.anticon) {
                display: none;
            }
            &:hover {
                opacity: 1;
            }
            .breakpoint-sm({
                span:not(.anticon ){
                    display: inline;
                }
            });
        }
        .matching_line {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;

            .line {
                cursor: pointer;
                stroke-width: 3px;
                transition: 0.3s;
                stroke-linecap: round;
                &:hover {
                    stroke: @error-color !important;
                    stroke-width: 4px;
                }
                .breakpoint-sm({
                    stroke-width: 6px;
                    &:hover {
                        stroke: @error-color !important;
                        stroke-width: 8px;
                    }
                });
            }
        }
        &.--has-voice {
            margin-top: 80px;
        }
    }
    &__audio {
        width: 50%;
        z-index: 1;
    }
    .audio_player {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 10px;
        span {
            font-size: 1.125rem;
            font-weight: 700;
        }

        .audio_progress {
            appearance: none; /* Remove default appearance */
            height: 12px; /* Set height */
            background-color: @light-1; /* Background color */
            border-radius: 6px; /* Rounded corners */
        }
        /* WebKit browsers (Chrome, Safari) */
        .audio_progress::-webkit-slider-runnable-track {
            width: 100%;
            height: 12px;
            background: linear-gradient(to right, @dark-1 0%, @dark-1 var(--range-progress), @light-1 var(--range-progress), @light-1 100%);
            border-radius: 6px;
        }

        .audio_progress::-webkit-slider-thumb {
            -webkit-appearance: none;
            height: 18px;
            width: 18px;
            background: @dark;
            border-radius: 50%;
            cursor: pointer;
            margin-top: -3px;
        }
    }

    &__children {
        z-index: 1;
    }

    // &__draff {
    //     height: 100%;
    //     .assignment-result__mark {
    //         width: 100%;
    //         span {
    //             font-size: 2rem;
    //             small {
    //                 font-size: 1.125rem;
    //                 color: @dark-0;
    //             }
    //         }
    //     }

    //     .grading_details {
    //         padding: 16px 8px 16px 16px;
    //         border-radius: 12px;
    //         background-color: @light-1;
    //         overflow-y: scroll;
    //         height: clamp(80%, calc(100% - 110px), 95%);
    //         &::-webkit-scrollbar {
    //             width: 8px;
    //         }

    //         &::-webkit-scrollbar-thumb {
    //             border: 0;
    //             background-color: transparent;
    //         }
    //         &:hover::-webkit-scrollbar-thumb {
    //             background-color: @dark-3;
    //         }
    //     }

    //     .draff__list {
    //         display: grid;
    //         gap: 10px;
    //         grid-template-columns: auto auto auto;
    //         &:not(:last-child) {
    //             padding-bottom: 1rem;
    //             margin-bottom: 1rem;
    //             border-bottom: 1px solid @dark-3;
    //         }
    //         &__name {
    //             grid-column: 1 / span 3;
    //             font-weight: 700;
    //             span {
    //                 color: @error-color;
    //                 font-weight: 800;
    //                 margin-left: 20px;
    //                 white-space: nowrap;
    //             }
    //         }
    //         &__matching {
    //             grid-column: 1 / span 3;
    //         }
    //         .draff__answer {
    //             width: 100%;
    //             border-radius: 8px;
    //             overflow: hidden;
    //             background-color: @white;
    //             display: inline-flex;
    //             font-weight: 700;
    //             border: 1px solid @dark-3;
    //             grid-column: 1 / span 3;
    //             .breakpoint-lg({
    //                 grid-column: unset;
    //             });
    //             &__key,
    //             &__value {
    //                 padding: 8px;
    //             }

    //             &__key {
    //                 background-color: @dark-3;
    //                 text-align: center;
    //             }

    //             &.--incorrect,
    //             &.--correct,
    //             &.--filled {
    //                 .draff__answer__key {
    //                     background-color: @primary;
    //                 }
    //                 &.--incorrect .draff__answer__key {
    //                     background-color: @error-color;
    //                 }

    //                 &.--correct .draff__answer__key {
    //                     background-color: @success-color;
    //                 }
    //             }

    //             &.--fill,
    //             &.__choice_many,
    //             &.__drag_drop,
    //             &.--writing {
    //                 grid-column: 1 / span 3;
    //             }

    //             &.__choice_many,
    //             &.__drag_drop {
    //                 .draff__answer__key {
    //                     width: auto;
    //                 }
    //             }
    //         }

    //         .draff__answer__matching {
    //             width: 100%;
    //             display: flex;
    //             border-radius: 8px;
    //             margin-bottom: 8px;
    //             overflow: hidden;
    //             font-weight: 700;
    //             border: 1px solid @dark-3;
    //             &__mat,
    //             &__ching {
    //                 padding: 8px;
    //                 text-align: center;
    //                 transition: all 0.25s ease;
    //                 width: 50%;
    //                 &:after {
    //                     content: "";
    //                     position: absolute;
    //                     top: 0;
    //                     border: 16px solid @dark-3;
    //                     z-index: 1;
    //                     transition: all 0.3s ease;
    //                     border-width: 20px 8px 21px 8px;
    //                 }
    //             }
    //             &__mat {
    //                 background-color: @dark-3;
    //                 position: relative;

    //                 &::after {
    //                     left: 100%;
    //                     border-color: transparent transparent transparent @dark-3;
    //                 }
    //                 &.--matched {
    //                     background-color: @primary-light-3;
    //                     &::after {
    //                         border-color: transparent transparent transparent @primary-light-3;
    //                     }
    //                 }
    //             }
    //             &__ching {
    //                 margin-left: 10px;
    //                 position: relative;
    //                 background-color: @white;
    //                 .ant-tag-has-color {
    //                     color: @white;
    //                 }
    //                 &::after {
    //                     left: 0;
    //                     border-color: transparent transparent transparent @light-1;
    //                 }
    //                 &.--matched {
    //                     opacity: 1;
    //                     margin-left: 0;
    //                     &::after {
    //                         border-color: transparent transparent transparent @primary-light-3;
    //                     }
    //                     // background-color: rgba(@info-color, 0.66);
    //                     // color: @white;
    //                     // &.--incorrect {
    //                     //     background-color: rgba(@error-color, 0.5) ;
    //                     // }
    //                     // &.--correct {
    //                     //     background-color: rgba(@success-color, 0.5) ;
    //                     // }
    //                 }
    //             }
    //         }
    //     }
    // }
}
